
import {
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useField } from "vee-validate";
export default defineComponent({
  name: "SearchDropdown",
  props: {
    options: { type: Array, default: [] as never[] },
    defaultOption: { type: String, default: "" },
    name: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    rules: { type: String, default: "required" },
    isCondition: { type: Boolean, default: false },
    scrollPos: { type: Number, default: 0 },
    listScrollable: { type: Boolean, default: false },
  },
  emits: ["onChange"],
  data() {
    return {
      // isShowDropdown: false,
    };
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const state = reactive({
      topValue: 0,
      isShowDropdown: false,
    });
    const { value: selectedId, errorMessage } = useField(
      props.name,
      props.rules,
      {
        initialValue: props.defaultOption,
      }
    );

    const selectedOptionName = ref("");

    onMounted(() => {
      if (props.defaultOption) {
        const options = props.options as never[];
        const option = options?.find(
          (item) => item["id"] === props.defaultOption
        ) as never;
        selectedOptionName.value = option["name"];
      } else {
        selectedOptionName.value = "";
      }
    });

    watch(
      () => props.defaultOption,
      (val) => {
        if (val != "") {
          const options = props.options as never[];
          selectedId.value = val;
          const option = options?.find((item) => item["id"] === val) as never;
          selectedOptionName.value = option["name"];
        }
      }
    );

    watch(
      () => props.scrollPos,
      (val) => {
        state.isShowDropdown = false;
      }
    );

    return {
      ...toRefs(state),
      errorMessage,
      selectedId,
      selectedOptionName,
      isMobile,
    };
  },

  mounted() {
    window.addEventListener("click", this.clickOutside);
  },
  deactivated() {
    window.removeEventListener("click", this.clickOutside);
  },

  methods: {
    onChange(id: string) {
      this.isShowDropdown = !this.isShowDropdown;
      this.selectedId = id;
      this.$emit("onChange", id);
    },
    openDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
      const dropdown = this.$refs.dropdown as HTMLElement;
      this.topValue =
        dropdown.offsetTop + dropdown.clientHeight - this.scrollPos;
    },
    clickOutside(e: any) {
      if (!this.$el.contains(e.target as Node)) {
        this.isShowDropdown = false;
      }
    },
  },
});
