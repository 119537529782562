
import { defineComponent } from "vue";

export default defineComponent({
  name: "BorderButton",
  props: {
    text: { type: String, required: true },
    enabled: { type: Boolean, default: true },
    icon: { type: String },
  },
});
