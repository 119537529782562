
import {
  defineComponent,
  inject,
  onBeforeMount,
  reactive,
  ref,
  toRefs,
} from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import searchIcon from "@/assets/icons/search.svg";
import searchBlackIcon from "@/assets/icons/search_black.svg";
import filterIcon from "@/assets/icons/filter.svg";
import closeIcon from "@/assets/icons/close.svg";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import SearchResult from "@/components/atomics/SearchResult.vue";
import BorderButton from "@/components/atomics/BorderButton.vue";
import SearchDropdown from "@/components/atomics/SearchDropdown.vue";
import { ContractRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import moment from "moment";

enum filterRowType {
  DATE,
  CONDITION_DISABLE,
  CONDITION_ENABLE,
}

interface SearchRow {
  id: number;
  autoUpdate: string;
  condition: string;
  value: string | Date;
  searchFieldSelected: never;
  type: filterRowType;
}

export default defineComponent({
  name: "HomeHeader",
  components: {
    TextButton,
    CustomDatepicker,
    FlatButton,
    SearchResult,
    BorderButton,
    SearchDropdown,
    ValidationTextField,
  },
  emits: ["onClickCloseSearchField"],
  data() {
    let dynamicId = 0;
    return {
      searchIcon: searchIcon,
      dynamicId,
      filterRowType,
      searchRows: [
        {
          id: 0,
          autoUpdate: "",
          condition: "",
          value: "",
          type: filterRowType.CONDITION_ENABLE,
          searchFieldSelected: {} as never,
        },
      ] as SearchRow[],
      searchBlackIcon: searchBlackIcon,
      filterIcon: filterIcon,
      closeIcon: closeIcon,
      isShowSearchInput: false,
      searchQuery: "",
      scrollPos: 0,
    };
  },
  methods: {
    onChange(id: string, index: number) {
      const optionSelected =
        this.searchOptions.find((item: never) => item["id"] === id) ??
        this.searchOptions[index];
      this.searchRows[index]["autoUpdate"] = optionSelected["id"];

      this.onChangeAutoUpdate(index);
    },
    onChangeCondition(id: string, index: number) {
      this.searchRows[index]["condition"] = id;
    },
    onChangeValue(id: string, index: number) {
      this.searchRows[index]["value"] = id;
    },
    onChangeAutoUpdate(index: number) {
      const searchField = this.searchOptions.find(
        (item) => item["id"] === this.searchRows[index]["autoUpdate"]
      );
      this.searchRows[index].searchFieldSelected = searchField as never;
      if (
        this.searchRows[index].searchFieldSelected["type"] === "text" ||
        this.searchRows[index].searchFieldSelected["type"] === "date" ||
        this.searchRows[index].searchFieldSelected["type"] === "number"
      ) {
        this.searchRows[index].condition = "";
        this.searchRows[index].value = "";
      }

      if (
        this.searchRows[index].searchFieldSelected["type"] === "bool" ||
        this.searchRows[index].searchFieldSelected["type"] === "list"
      ) {
        this.searchRows[index].value =
          this.searchRows[index].searchFieldSelected["values"][0]["id"];
      }
    },
    onChangeDate(value: Date, index: number) {
      this.searchRows[index]["value"] = moment(value).format('YYYY-MM-DD');
    },
    onClickOpenSearchField(): void {
      this.isShowSearchInput = !this.isShowSearchInput;
    },
    onClickCloseSearchField(): void {
      this.$emit("onClickCloseSearchField");
      if (this.searchResults != null) {
        this.searchResults = {} as never;
      }
      this.isShowSearchResult = false;
      this.isShowSearchInput = !this.isShowSearchInput;
    },
    onClickOpenAdvanceSearch(): void {
      this.isShowAdvanceSearch = !this.isShowAdvanceSearch;
      this.isShowSearchResult = false;
    },
    addMoreRow() {
      if (this.searchRows.length >= 10) {
        return;
      }
      this.dynamicId++;
      this.searchRows.push({
        id: this.dynamicId,
        autoUpdate: "",
        condition: "",
        value: "",
        searchFieldSelected: {} as never,
        type: filterRowType.CONDITION_DISABLE,
      });
      if (this.searchRows.length == 10) {
        this.enableAddFilter = false;
      }
    },
    removeRow(id: number) {
      if (this.searchRows.length <= 10) {
        this.enableAddFilter = true;
      }
      if (this.searchRows.length > 1) {
        this.searchRows = this.searchRows.filter((row) => row.id !== id);
      } else {
        this.searchRows[0].autoUpdate = "";
      }
    },
    onClickCancelSearch() {
      this.isShowAdvanceSearch = false;
      this.dynamicId = 0;
      this.searchRows = [
        {
          id: this.dynamicId,
          autoUpdate: "",
          condition: "",
          value: "",
          searchFieldSelected: {} as never,
          type: filterRowType.CONDITION_ENABLE,
        },
      ];
    },
    onClickClearSearch() {
      this.isShowSearchResult = false;
      this.searchKeyword = "";
      this.searchKeywordTextField = "";
    },
    // async valueChange() {
    //   if (this.searchKeyword === "") {
    //     this.isShowSearchResult = false;
    //   } else {
    //     await this.search();
    //     this.isShowSearchResult = true;
    //   }
    //   this.isShowAdvanceSearch = false;
    // },
    async onClickSearchQuery() {
      // await this.search();
      // this.isShowSearchResult = true;
      // this.isShowAdvanceSearch = false;
    },
    async onClickAvancedSearch() {
      const isValid = await this.submit();
      if (isValid) {
        await this.searchAdvanced(this.searchRows as never[]);
        this.searchKeyword = "";
        this.searchKeywordTextField = "";
        this.isShowSearchResult = true;
        this.isShowAdvanceSearch = false;
      }
    },

    checkEmpty() {
      let enable = false;
      this.searchRows.forEach((element) => {
        if (element.autoUpdate == "" || element.value == "") {
          enable = true;
        }
      });
      return enable;
    },

    handleScroll(event: any) {
      this.scrollPos = event.target.scrollTop;
      // Any code to be executed when the window is scrolled
    },
  },

  setup() {
    const state = reactive({
      isShowSearchResult: false,
      isShowAdvanceSearch: false,
      enableAddFilter: true,
      searchResults: {},
    });
    const isMobile = inject("isMobile");
    const searchOptions = ref([]);
    const searchKeyword = ref("");
    const searchKeywordTextField = ref("");
    const {
      getAdvancedSearchFields,
      searchWithKeyword,
      searchWithAdvancedField,
    } = searchMethods();

    let delay: ReturnType<typeof setTimeout>;
    const search = (value: any) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeyword.value =
          searchKeywordTextField.value +
          (value.isComposing ? value?.data ?? "" : "");

        state.isShowAdvanceSearch = false;
        if (searchKeyword.value === "") {
          state.isShowSearchResult = false;
          state.searchResults = {} as never;
        } else {
          state.searchResults = await searchWithKeyword(searchKeyword.value);
          state.isShowSearchResult = true;
        }
      }, 300);
    };

    const form = useForm({});
    const { formHandle } = useFormHandler(form, async () => {
      return true;
    });
    const submit = async function () {
      const res = await formHandle().catch(() => {
        return false;
      });
      return res;
    };

    const searchAdvanced = async (data: never[]) => {
      state.searchResults =
        ((await searchWithAdvancedField(data)) as never) ?? {};
    };

    onBeforeMount(async () => {
      searchOptions.value = (await getAdvancedSearchFields()) as never[];
    });

    const scrollToBottom = (value: boolean) => {
      if (!value) return;
      let item = document.querySelector(".search-content");
      item?.scrollTo({
        top: item.scrollHeight,
        behavior: "smooth",
      });
    };

    return {
      ...toRefs(state),
      searchOptions,
      search,
      scrollToBottom,
      searchAdvanced,
      searchKeyword,
      searchKeywordTextField,
      isMobile,
      submit,
      form,
    };
  },
});

const searchMethods = () => {
  const {
    getAdvancedSearchFields,
    searchWithKeyword,
    searchWithAdvancedField,
  } = RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  return {
    getAdvancedSearchFields,
    searchWithKeyword,
    searchWithAdvancedField,
  };
};
