
import { defineComponent, onMounted, ref } from "vue";
import TheSideBar from "@/components/organisms/TheSideBar.vue";
import AfterLoginHeader from "@/components/organisms/AfterLoginHeader.vue";
import { CompanyRepository, RepositoryFactory } from "@/lib/https";
import { useStore } from "vuex";

export default defineComponent({
  name: "AfterLoginLayout",
  components: {
    TheSideBar,
    AfterLoginHeader,
  },
  setup() {
    const isShowSidebar = ref(false);
    const companyInformation = ref({});
    const store = useStore();

    const { getCompany } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    onMounted(async () => {
      companyInformation.value = await getCompany();
    });

    return {
      isShowSidebar,
      companyInformation,
      store,
    };
  },

  methods: {
    onSelectedChanged() {
      this.store.dispatch("header/setHambugerState", false);
      this.isShowSidebar = false;
    },
  },
});
