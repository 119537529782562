type Menu = {
  path: string;
  name: string;
  localeText: string;
  children: Array<Menu>;
  childExpand: boolean;
};

const locale = "sidebar.";

export const sideBarMenu: Array<Menu> = [
  {
    path: "/dashboard",
    name: "Dashboard",
    localeText: `${locale}home`,
    children: [],
    childExpand: false,
  },

  // {
  //   path: "/contract",
  //   name: "Contract",
  //   localeText: `${locale}contractManagement`,
  //   children: [],
  //   childExpand: false
  // },
  {
    path: "/folder",
    name: "Folder",
    localeText: `${locale}folderManagement`,
    children: [],
    childExpand: false,
  },
  {
    path: "/template",
    name: "TemplateList",
    localeText: `${locale}templateManagement`,
    children: [],
    childExpand: false,
  },
  {
    path: "/account",
    name: "Account",
    localeText: `${locale}accountManagement`,
    children: [
      {
        path: "/organization-details",
        name: "OrganizationDetails",
        localeText: `${locale}organizationDetails`,
        children: [],
        childExpand: false,
      },
      {
        path: "/team-member",
        name: "TeamMember",
        localeText: `${locale}teamMembers`,
        children: [],
        childExpand: false,
      },
      {
        path: "/group",
        name: "Group",
        localeText: `${locale}group`,
        children: [],
        childExpand: false,
      },
      {
        path: "/invoice",
        name: "Invoice",
        localeText: `${locale}invoice`,
        children: [],
        childExpand: false,
      },
    ],
    childExpand: false,
  },
  {
    path: "/contact",
    name: "Contact",
    localeText: `${locale}contactInfo`,
    children: [],
    childExpand: false,
  },
];

export const footerMenu: Array<Menu> = [
  {
    path: "https://services.digitalsign.jp/terms",
    name: "termsOfUse",
    localeText: `${locale}termsOfUse`,
    children: [],
    childExpand: false,
  },
  {
    path: "https://services.digitalsign.jp/laws",
    name: "notation",
    localeText: `${locale}notation`,
    children: [],
    childExpand: false,
  },
  {
    path: "https://services.digitalsign.jp/privacy",
    name: "privacy",
    localeText: `${locale}privacy`,
    children: [],
    childExpand: false,
  },
  {
    path: "https://services.digitalsign.jp/corporate",
    name: "operating-company",
    localeText: `${locale}operatingCompany`,
    children: [],
    childExpand: false,
  },
  // {
  //   path: "/media",
  //   name: "media",
  //   localeText: `${locale}media`,
  //   children: [],
  //   childExpand: false
  // },
  {
    path: "https://help.digitalsign.jp/",
    name: "faq",
    localeText: `${locale}faq`,
    children: [],
    childExpand: false,
  },
  {
    path: "https://help.digitalsign.jp/hc/ja/requests/new",
    name: "contactUs",
    localeText: `${locale}contactUs`,
    children: [],
    childExpand: false,
  },
];
