
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  computed,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import SearchBar from "@/components/organisms/SearchBar.vue";
import searchIcon from "@/assets/icons/search.svg";
import searchBlackIcon from "@/assets/icons/search_black.svg";
import upgradePlanIcon from "@/assets/icons/diamond-white.svg";
import defaultAvatar from "@/assets/icons/default-avatar.svg";
import filterIcon from "@/assets/icons/filter.svg";
import closeIcon from "@/assets/icons/close.svg";
import FlatButton from "@/components/atomics/FlatButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import {
  AccountRepository,
  InvoiceRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useRouter } from "vue-router";
import storage from "@/lib/storage/storageHelper";
import { useStore } from "vuex";
import UpgradePlanPopup from "@/components/popups/UpgradePlanPopup.vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "AfterLoginHeader",
  components: {
    TextButton,
    AvatarCircle,
    FlatButton,
    MenuOptionDropdown,
    SearchBar,
    UpgradePlanPopup,
  },
  emits: ["onShowSidebar"],
  data() {
    let dynamicId = 0;
    return {
      searchIcon: searchIcon,
      dynamicId,
      searchBlackIcon: searchBlackIcon,
      upgradePlanIcon: upgradePlanIcon,
      defaultAvatar: defaultAvatar,
      filterIcon: filterIcon,
      closeIcon: closeIcon,
      isShowSearchInput: false,
      isShowAdvanceSearch: false,
      isShowSearchResult: false,
      isShowMyAccount: false,
      isShowUpgradePlanPopup: false,
      isMobileMenuOpen: false,
      isHambugerMenu: true,
    };
  },
  setup() {
    const state = reactive({
      profile: {
        id: "",
        profilePicture: "",
        lastName: "",
        firstName: "",
        email: "",
      },
    });
    const router = useRouter();
    const store = useStore();
    const localStorage = storage.getLocalStorage();

    const { getFullProfile } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

    const { getPlanInfo } =
      RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);

    const logout = () => {
      localStorage.clear();
      router.push({ name: "Login" });
    };

    const accountInfo = store.getters["header/getAccountInfo"];
    onBeforeMount(async () => {
      const profilePayload = await getFullProfile();
      const planInfo = await getPlanInfo();
      if (profilePayload) {
        await Promise.all([
          store.dispatch("header/setAccount", {
            fullName: profilePayload.lastName + " " + profilePayload.firstName,
            avatar: profilePayload.profilePicture
              ? profilePayload.profilePicture: "default",
            id: profilePayload.id,
            role: profilePayload.role,
            planType: planInfo.planType,
            email: profilePayload.email,
            primaryPaymentMethodInfo: planInfo.primaryPaymentMethodInfo,
          }),
        ]);

        const userFullName =
          profilePayload.lastName + " " + profilePayload.firstName;
        localStorage.add("userFullName", userFullName ?? "");
        state.profile = profilePayload;
        state.profile.profilePicture = profilePayload.profilePicture
          ? profilePayload.profilePicture
          : 'default';
      }
    });

    return { ...toRefs(state), logout, accountInfo, store, getFilePathFromUrl };
  },
  mounted() {
    window.addEventListener("click", this.dismiss);
  },

  unmounted() {
    window.removeEventListener("click", this.dismiss);
  },
  methods: {
    dismiss(event: Event) {
      if (
        this.isShowMyAccount &&
        !(this.$el as HTMLElement).contains(event.target as Element)
      ) {
        this.isShowMyAccount = false;
      }
    },
    onHambugerClick() {
      this.isHambugerMenu = !this.isHambugerMenu;
      this.store.dispatch(
        "header/setHambugerState",
        !this.store.getters["header/getIsHambugerMenu"]
      );
      this.$emit("onShowSidebar", this.isHambugerMenu);
    },
    navigationToAccount(): void {
      this.isShowMyAccount = !this.isShowMyAccount;
      this.$router.push("/account");
    },
    onClickOpenSearchField(): void {
      this.isShowSearchInput = !this.isShowSearchInput;
    },
    onClickSearch(): void {
      this.isShowSearchResult = false;
      this.isShowSearchInput = !this.isShowSearchInput;
    },
    onClickPremium(): void {
      this.isShowUpgradePlanPopup = true;
      // this.$router.push("/pricing-plan");
    },
    onClickAvatar() {
      this.isShowMyAccount = !this.isShowMyAccount;
    },
  },
});
