
import { defineComponent, toRefs, reactive, inject, ref } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import directoryOrange from "@/assets/icons/directory_orange.svg";
import reportOrange from "@/assets/icons/report_orange.svg";
import reportGrey from "@/assets/icons/report_grey.svg";
import AccessCodePopup from "@/components/popups/sign/AccessCodePopup.vue";
// import BreadScrum from "@/components/atomics/BreadScrum.vue";
import BorderButton from "@/components/atomics/BorderButton.vue";
import { formatFolderSearchPath } from "@/lib/utility/common";
import { ContractRepository, RepositoryFactory } from "@/lib/https";
import { useRouter } from "vue-router";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
// import folder from "@/locales/ja/folder";

export default defineComponent({
  name: "SearchResult",
  props: {
    text: { type: String, required: true },
    results: { type: Object, required: true, default: {} as never },
  },
  components: {
    TextButton,
    BorderButton,
    AccessCodePopup,
  },
  emits: ["onShowSearchAdvanced", "onCancelSearch", "onClickClearSearch"],
  setup(_, { emit }) {
    const isMobile = inject("isMobile");
    const router = useRouter();
    const store = useStore();
    const { setFieldError } = useForm();

    const contractSelected = ref();
    const isShowAccessCodePopup = ref(false);

    const { getContractFullInformation } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);
    const { getAccessCode, saveAccessCode } = useSignFunctions();

    const goToContract = async (contract: any) => {
      if (contract.isFile) {
        emit("onCancelSearch");
        router.push({
          name: "FileDetail",
          params: {
            id: contract.id,
          }
        });
        return;
      }
      try {
        const accessCode = getAccessCode(contract.id);
        await getContractFullInformation(contract.id, accessCode || "");
        emit("onCancelSearch");
        if (contract.status >= 3)
          router.push(`/sign/done?contractId=${contract.id}`);
        else
          router.push(
            `/guest/contract-detail-readonly?contractId=${contract.id}&isUser=true`
          );
      } catch (e: any) {
        isShowAccessCodePopup.value = true;
        contractSelected.value = contract;
      }
    };

    const handleInputAccessCode = async (accessCode: string) => {
      try {
        await getContractFullInformation(contractSelected.value.id, accessCode);
        saveAccessCode(contractSelected.value.id, accessCode);
        isShowAccessCodePopup.value = false;
        goToContract(contractSelected.value);
      } catch (e: any) {
        setFieldError("accessCode", e.data.message);
      }
    };

    return {
      isMobile,
      formatFolderSearchPath,
      goToContract,
      handleInputAccessCode,
      isShowAccessCodePopup,
    };
  },
  data() {
    return {
      folderIcon: directoryOrange,
      contractIcon: reportOrange,
      documentIcon: reportGrey,
    };
  },
  methods: {
    onClickClearSearch() {
      this.$emit("onClickClearSearch");
    },
    openSearchAdvance() {
      this.$emit("onShowSearchAdvanced");
    },
    goToFolder(id: string): void {
      this.$router.push(`/folder?folderId=${id}`);
      this.$emit("onCancelSearch");
    },
  },
});
