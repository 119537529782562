
import { defineComponent, reactive, watch } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { sideBarMenu as sidebar, footerMenu as footer } from "@/models/menu";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useStore } from "vuex";
import useContract from "@/views/pages/contract/contract";

export default defineComponent({
  name: "TheSideBar",
  components: {
    FlatButton,
  },
  props: {
    companyInformation: { type: Object },
  },
  emits: ["onSelectedChanged"],
  setup(props) {
    const sideBarMenu = reactive(sidebar);
    const footerMenu = reactive(footer);
    const store = useStore();

    const companyInfo = store.getters["template/getCompanyInfo"];
    const accountInfo = store.getters["header/getAccountInfo"];

    const { resetState } = useContract();

    watch(
      () => props.companyInformation,
      async () => {
        if (props.companyInformation) {
          await Promise.all([
            store.dispatch("template/setCompany", {
              name: props.companyInformation.name,
              logo: props.companyInformation.logo
                ? props.companyInformation.logo
                : "default",
            }),
          ]);
        }
      }
    );

    return {
      sideBarMenu,
      footerMenu,
      selected: sideBarMenu[0].name,
      getFilePathFromUrl,
      companyInfo,
      accountInfo,
      resetState,
    };
  },
  mounted() {
    this.selected = this.$route.name?.toString() ?? this.sideBarMenu[0].name;
    this.expandSelectedChild();
  },
  methods: {
    selectItem(name: string) {
      this.sideBarMenu.forEach((menu) => {
        menu.childExpand = false;
      });
      this.selected = name;
      this.$router.push({ name: name });
      this.$emit("onSelectedChanged");
    },

    selectChildItem(name: string) {
      this.selected = name;
      this.$router.push({ name: name });
      this.$emit("onSelectedChanged");
    },

    routeTo(name: string) {
      if (this.$route?.name?.toString().includes(name)) return;
      this.$router.push({ name: name });
      this.$emit("onSelectedChanged");
    },

    expandSelectedChild() {
      for (var item of this.sideBarMenu) {
        if (item.children.length > 0) {
          for (var child of item.children) {
            if (this.$route.name == child.name) {
              item.childExpand = true;
              return;
            }
          }
        }
      }
    },

    addContract() {
      if (!this.$route?.name?.toString().includes('ContractDone')) {
        this.routeTo('Contract');
      }
      else {
        this.resetState();
        this.$router.push({ name: "Contract" });
      }
    },
  },
});
