import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-772c023a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "message mobile" }
const _hoisted_4 = { class: "message desktop" }
const _hoisted_5 = { class: "message desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('pricingPlan.upgradePlanPopupTitle'),
    onOnClickOutsidePopup: _ctx.hidePopup,
    onOnHide: _ctx.hidePopup
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: "upgrade-plan-img",
            src: require('images/upgrade-plan-img.svg'),
            alt: ""
          }, null, 8, _hoisted_2),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("pricingPlan.upgradePlanPopupMessage")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("pricingPlan.upgradePlanPopupMessage1")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("pricingPlan.upgradePlanPopupMessage2")), 1)
        ]),
        _createVNode(_component_flat_button, {
          text: _ctx.$t('pricingPlan.upgradePlanPopupBtn'),
          class: "payment-btn w-full",
          onClick: _ctx.goToPricingPlanPage
        }, null, 8, ["text", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
}