
import { defineComponent } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";

export default defineComponent({
  name: "UpgradePlanPoup",
  components: {
    FlatButton,
    Popup,
  },
  methods: {
    goToPricingPlanPage() {
      this.$router.push("/pricing-plan");
    },

    hidePopup() {
      this.$emit("hidePopup");
    },
  },
});
