import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32cc2f78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "search-custom-select-wrapper",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openDropdown && _ctx.openDropdown(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'condition-border': _ctx.isCondition,
        error: _ctx.errorMessage,
        'remove-border': _ctx.isShowDropdown,
      }, "custom-select__trigger"]),
      ref: "dropdown"
    }, [
      (_ctx.selectedOptionName != '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.selectedOptionName), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.placeholder), 1)),
      _createElementVNode("img", {
        class: "icon",
        src: require('icons/icon_dropdown.svg')
      }, null, 8, _hoisted_3)
    ], 2),
    (_ctx.isShowDropdown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "custom-options",
          id: "custom-options",
          style: _normalizeStyle({
        top: !_ctx.isMobile ? `${this.topValue}px` : '100%',
        width: !_ctx.isMobile
          ? _ctx.isCondition
            ? `calc(25% - 10px)`
            : `calc(35% - 14px)`
          : '100%',
      })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("span", {
              key: option.id,
              class: _normalizeClass(["custom-option", { selected: option.id == _ctx.selectedId }]),
              onClick: _withModifiers(($event: any) => (_ctx.onChange(option.id)), ["stop"]),
              "data-value": "option"
            }, _toDisplayString(option?.name), 11, _hoisted_4))
          }), 128))
        ], 4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event)),
      style: { display: 'none' }
    }, null, 512), [
      [_vModelText, _ctx.selectedId]
    ])
  ]))
}